
export const Service = () => {

    return (
      <>
      <section className="service">
      <h1 className="service-heading1">SERVICES</h1>
      {/* <div className="hero-content"> */}
      <img src="images/service1car.png" alt="car rent" className="card-image1" />

<div className="service-cards-container">

<div className="service-card1">

<h3 className="card-title1">CAR RENTAL</h3>
<p className="card-description1"> <h5 className="num">7714511786</h5></p>
</div>

<div className="service-card1">
<h3 className="card-title1">TOUR OPERATOR</h3>
<p className="card-description1"> <h5 className="num">9425214360</h5></p>
</div>
<div className="service-card1">
<h3 className="card-title1">TAXI SERVICE</h3>
<p className="card-description1"> <h5 className="num">9301922600</h5></p>
</div>


</div>

      {/* </div> */}
     
     
<h1 className="service-heading">DESTINATION</h1>
<div className="service-destination">

    <div className="service-card">
      <img src="images/Mainpat_1.jpg" alt="Destination 1" className="card-image" />
      <h3 className="card-title">Mainpat</h3>
      <p className="card-description">Mainpat is a hill station and small village in the Surguja district in the northern part of the state of Chhattisgarh, India. It lies about 55 kilometres (34 mi) by road from the divisional headquarter of Ambikapur. The hill station and 360 km northeast of state capital Raipur.</p>
    </div>
    <div className="service-card">
      <img src="images/kanger valley.jpg" alt="Destination 2" className="card-image" />
      <h3 className="card-title">Kanger valley</h3>
      <p className="card-description">Kanger Valley National Park in Chhattisgarh is a biodiversity hotspot in Central India with a variety of habitats, including valleys, plateaus, gorges, and steep slopes. The park is home to a diverse range of flora and fauna, including:</p>
    </div>
    <div className="service-card">
      <img src="images/east champaran.jpg" alt="Destination 3" className="card-image" />
      <h3 className="card-title">Champaran</h3>
      <p className="card-description">Champaran is a historic region in the state of Bihar, India, that is now divided into two districts: East Champaran and West Champaran. The region is known for its role in the Champaran Satyagraha movement, led by Mahatma Gandhi</p>
    </div>
    <div className="service-card">
      <img src="images/kutumsar_1.jpg" alt="Destination 4" className="card-image" />
      <h3 className="card-title">Kutumsar</h3>
      <p className="card-description">Kotumsar cave is a limestone cave located near Jagdalpur in the Indian state of Chhattisgarh. Kotumsar cave is a major attraction for people interested in ecotourism.</p>
    </div>
    <div className="service-card">
      <img src="images/barnavapara_1.jpg" alt="Destination 5" className="card-image" />
      <h3 className="card-title">Barnawapara</h3>
      <p className="card-description">Barnawapara Wildlife Sanctuary is a 244.66 square kilometer sanctuary in Chhattisgarh, India thats known for its unique wildlife and green vegetation. Here are some details about the sanctuary</p>
    </div>
    <div className="service-card">
      <img src="images/achanakmar-1.jpg" alt="Destination 6" className="card-image" />
      <h3 className="card-title">Achanakmar</h3>
      <p className="card-description">Achanakmar Tiger Reserve is in the Bilaspur district of Chhattisgarh. The Achanakmar-Amarkantak Biosphere Reserve is spread across the Bilaspur and Marwahi forest divisions of Chhattisgarh, and the Dindori and Anuppur forest divisions of Madhya Pradesh</p>
    </div>
    <div className="service-card">
      <img src="images/kumeli ghat.jpg" alt="Destination 7" className="card-image" />
      <h3 className="card-title">Kumeli ghat waterfall</h3>
      <p className="card-description">Kumeli Ghat Waterfall is a mesmerizing retreat for nature enthusiasts and adventure seekers. Known for its untouched beauty and serene surroundings, this cascading marvel promises an unforgettable experience.</p>
    </div>
    <div className="service-card">
      <img src="images/Guru_Ghasidas-Tamor_Pingla_Tiger_Reserve.jpg" alt="Destination 8" className="card-image" />
      <h3 className="card-title">Tamor pingla </h3>
      <p className="card-description">Tamor Pingla Wildlife Sanctuary is located in Surajpur District, Chhattisgarh, in central India. It is named after the Tamor Hill and Pingla Nalla, the old and prominent features of the area. In 2021, National Tiger Conservation Authority has approved the Chhattisgarh governments proposal to declare the combined areas of the Guru Ghasidas National Park and Tamor Pingla Wildlife Sanctuary as a Tiger Reserve.</p>
    </div>
    <div className="service-card">
      <img src="images/madku_dweep.jpg" alt="Destination 9" className="card-image" />
      <h3 className="card-title">Madku Dweep</h3>
      <p className="card-description">Madku Dweep - Siva Shakti - Smarta linga temples - where Manduka Upanishad was written there. Manduka Rishi lived there. " Satyameva Jayate " on India s National Emblem is taken from this Upanishad. This is an islan in Shivnath River. Serene location is a spiritually energizing , though the island is treated as a picnic location.</p>
    </div>
  </div>
    
      </section>

      </>
    )
  }
  